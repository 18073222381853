module.exports = {
    'WAX': {
        apiUri: "https://wax.greymass.com:443",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "blockspitest" : "blockspingam",
        chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
        decimals: 2
    },
    'SOL': {
        apiUri: "https://rpc.helius.xyz/?api-key=dac8eb32-8210-407d-9d27-d07c63fc609d",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "EWFJCAn4X8FPnkxKZhho7HwTVtRPSazWXHFSggfHXV2K" : "2p7iR2vFFuUFcX8GyqgczrSArWrjFWJajxMVfU9oJwtL",
        decimals: 4
    },
    'RON': {
        apiUri: "https://api-gateway.skymavis.com/",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "0xdd8363f5890a97e5703d6c150faf5eab0df05729" : "0xe8549afc9180475ed3d83ca392c61bdb440f9efa",
        decimals: 4
    }
}