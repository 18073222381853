<template>
  <div id="app" :class="['l-main', { 'l-main--home': isHomeScreen }]">
    <cookie-popup />
    <router-view />
  </div>
</template>

<script>
import CookiePopup from "./components/CookiePopup";

export default {
  name: "App",
  components: {
    CookiePopup,
  },
  data() {
    return {
      isHomeScreen: false,
    };
  },
  watch: {
    $route(to, from) {
      this.isHomeScreen = to.name === "home";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main";
@import "./assets/styles/transitions";
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');
.l-main--home {
  background: $white;
}

</style>