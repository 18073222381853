
import config from '../config.js'
let JsonRpc, UAL, Wax, Anchor;

async function loadLibraries() {
    if(!JsonRpc) JsonRpc = (await import('eosjs')).JsonRpc;
    if(!UAL) UAL = (await import('universal-authenticator-library')).UAL;
    if(!Wax) Wax = (await import('@eosdacio/ual-wax')).Wax;
    if(!Anchor) Anchor = (await import('ual-anchor')).Anchor;
}


const appName = 'blockspingam';

function getPermission(user) {
    if (user.scatter) {
        return user.scatter.identity.accounts[0].authority;
    }
    if (user.requestPermission) {
        return user.requestPermission;
    }
    if (user.wallet.permissions) {
        return this.user.wallet.permissions;
    }
}

export default class Eos {

    constructor(chain) {
        this.user = null;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.accountName ? this.user.accountName : this.user.wallet.name;
    }

    async setChain(chain) {
        if (chain == this.chain) {
            return;
        }
        await loadLibraries();
        if(config[chain]) {
            this.chain = chain;
            this.chainId = config[this.chain].chainId;
            localStorage.setItem('chain', chain);

            this.rpc = new JsonRpc(config[chain].apiUri);

            const endpoint = config[chain].apiUri;
            const indexOfFirstColon = endpoint.indexOf(':');
            const indexOfSecondColon = endpoint.slice(indexOfFirstColon + 1).indexOf(':') + indexOfFirstColon + 1;

            const chains = [{
                chainId: this.chainId,
                rpcEndpoints: [{
                    protocol: endpoint.slice(0, indexOfFirstColon),
                    host: endpoint.slice(indexOfFirstColon + 3, indexOfSecondColon),
                    port: endpoint.slice(indexOfSecondColon + 1),
                }]
            }];

            if (this.user) {
                await this.logout();
            }

            this.ual = new UAL(chains, this.dappName, [
                new Wax(chains, { appName }),
                new Anchor(chains, { appName }),
            ]);

            this.ual.authenticators[0].walletName = 'wax';
            this.ual.authenticators[1].walletName = 'anchor';
        }
    }

    async login(wallet) {
        if (this.user) {
            await this.logout();
        }
        await loadLibraries();

        let authenticator = this.ual.authenticators.find(
          auth => auth.walletName.toLowerCase() == wallet.toLowerCase()
        );

        if(authenticator) {
            this.wallet = wallet;
            this.authenticator = authenticator;

            try {
                await this.authenticator.init();

                try {
                    const users = await this.authenticator.login(null, 0, 0, 0);
                    this.user = users[0];
                    if (this.user) {
                        console.log(this.user);
                        if (this.user.scatter && this.user.scatter.identity.accounts[0].chainId != this.chainId) {
                            await this.logout();
                        } else {
                            localStorage.setItem('wallet', wallet);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    //this.loginMessage = `Error: ${error}`;
                }
            } catch (err) {
                console.log(err);
                /*let m = "Wallet unavailable";
                if (this.authenticator) {
                    m = this.authenticator.getError() || err;
                    m += `</br> <a target="_blank" href=${this.authenticator.getOnboardingLink()}>${this.authenticator.constructor.name
                        } Website</a>`;
                }
                this.loginMessage = m;*/
            }
        }
    }

    async logout() {
        await this.authenticator.logout();
        this.user = null;
    }

    async makePurchase(purchaseId, totalPrice) {
        const actions = [{
            account: 'eosio.token',
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: config[this.chain].contract,
                quantity: totalPrice.toFixed(8) + ' WAX',
                memo: 'purchase:' + purchaseId
            },
        }];

        return await this.transaction(actions);
    };

    async transaction(actions, broadcast = true) {
        try {
            let t = await this.user.signTransaction({ actions }, { broadcast, blocksBehind: 3, expireSeconds: 30 });
            return t.transactionId;
        } catch (e) {
            alert(e);
        }
    }

    async getWalletBalance(accountName) {
        let result = await this.rpc.get_currency_balance('eosio.token', accountName, 'WAX');
        if (result.length) {
            return +result[0].replace(' WAX', '');
        }
        return 0
    }
}