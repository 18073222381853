import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { onMessage } from "firebase/messaging";
import backend from "@/classes/backend.js";
import {store} from "@/store";

var firebaseConfig = {
    apiKey: "AIzaSyAS9GZB0GYYcpJkvAlpqU-OUG_4maHQJAQ",
    authDomain: "blockspin-367308.firebaseapp.com",
    projectId: "blockspin-367308",
    storageBucket: "blockspin-367308.appspot.com",
    messagingSenderId: "670546952853",
    appId: "1:670546952853:web:a813eae6665464c01e519c",
    measurementId: "G-TQF96NXWV7"
};

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export function initMessaging(){
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get the token once the service worker is registered
            navigator.serviceWorker.ready.then((registration) => {
                getToken(messaging, {
                    vapidKey: "BJqZaqRqHvQAPhtAyXF7sQvBZ02ChDMyiIrzq_mI6q-RzTEp-T51guIGmReen0NQeSekwWQpZRrzKaw9IxUHiyM",
                    serviceWorkerRegistration: registration
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            console.log('Token:', currentToken);
                            // Save the token in the store
                            //store.dispatch('saveToken', currentToken);
                            console.log(store.getters.getUserId);
                            // Sending the token to backend
                            backend.setPushNotifToken(store.getters.getUserId, currentToken)
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    })
                    .catch((err) => {
                        console.error('An error occurred while retrieving token. ', err);
                    });
            });
        }
        else{
            console.log("permission === "+permission);      //Permission denied
            backend.setPushNotifToken(store.getters.getUserId, "").then((result)=>{
                console.log('result for empty token:', JSON.stringify(result));
            })
        }
    });

// Listen for messages while the app is in the foreground
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // Handle the message as needed
    });
}

initMessaging();
export default messaging;
